define("discourse/plugins/discourse-snippet/lib/discourse-markdown/snippets", ["exports", "pretty-text/emoji"], function (_exports, _emoji) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setup = setup;
  // import the regex variable used by discourse's emoji plugin to perform emoji escape

  function getTagInfoAttributes(tagInfo, content) {
    let attrs = [];
    if (tagInfo.attrs['shortcut']) {
      attrs.push(['data-shortcut', tagInfo.attrs['shortcut']]);
    }
    if (tagInfo.attrs['quickentry']) {
      attrs.push(['data-quickentry', tagInfo.attrs['quickentry']]);
    }
    if (tagInfo.attrs['hidePreview']) {
      attrs.push(['data-hide-preview', tagInfo.attrs['hidePreview']]);
    }
    const contentEncoded = content.replace(new RegExp(_emoji.emojiReplacementRegex, "g"), match => {
      return `{{^^${encodeURIComponent(match)}^^}}`;
    });
    return {
      contentEncoded,
      attrs
    };
  }
  function setup(helper) {
    helper.allowList(['div.discourse-snippet', 'div.discourse-space', 'div.discourse-bundle', 'div.snippet-preview-prompt']);
    helper.registerOptions((opts, siteSettings) => {
      opts.features['snippet'] = !!siteSettings.snippet_enabled;
      opts.features['prompt'] = !!siteSettings.snippet_enabled;
      opts.features['space'] = !!siteSettings.snippet_enabled;
      opts.features['bundle'] = !!siteSettings.snippet_enabled;
    });
    helper.registerPlugin(md => {
      md.block.bbcode.ruler.push('snippet', {
        tag: 'snippet',
        replace: function (state, tagInfo, content) {
          const {
            attrs,
            contentEncoded
          } = getTagInfoAttributes(tagInfo, content);
          const token = state.push("wrap_open", "div", 1);
          token.attrs = [...attrs, ['class', 'discourse-snippet'], ['data-name', tagInfo.attrs['name'] || 'Text Blaze Snippet']];
          md.block.parse(contentEncoded, state.md, state.env, state.tokens);
          state.push("wrap_close", "div", -1);
          return true;
        }
      });
      md.block.bbcode.ruler.push('bundle', {
        tag: 'bundle',
        wrap: function (token, tagInfo) {
          token.attrs = [['class', 'discourse-bundle'], ['data-name', tagInfo.attrs['name'] || 'Text Blaze Bundle'], ['data-hide-preview', tagInfo.attrs['hidePreview'] || 'false']];
          if (tagInfo.attrs['id']) {
            token.attrs.push(['data-id', tagInfo.attrs['id']]);
          }
          return true;
        }
      });
      md.block.bbcode.ruler.push('space', {
        tag: 'space',
        replace: function (state, tagInfo, content) {
          let token;
          token = state.push("wrap_open", "div", 1);
          token.attrs = [['class', 'discourse-space'], ['data-code', JSON.stringify({
            code: content
          })]];
          if (tagInfo.attrs['name']) {
            token.attrs.push(['data-name', tagInfo.attrs['name']]);
          }
          if (tagInfo.attrs['icon']) {
            token.attrs.push(['data-icon', tagInfo.attrs['icon']]);
          }
          state.push("wrap_close", "div", -1);
          return true;
        }
      });
      md.block.bbcode.ruler.push('prompt', {
        tag: 'prompt',
        replace: function (state, tagInfo, content) {
          const {
            attrs,
            contentEncoded
          } = getTagInfoAttributes(tagInfo, content);
          const token = state.push("wrap_open", "div", 1);
          token.attrs = [...attrs, ['class', 'discourse-snippet'], ['data-is-ai', 'true'], ['data-name', tagInfo.attrs['name'] || 'AI Blaze prompt'], ['data-hide-preview', 'flipped']];

          /**
           * AI Blaze prompts only support plain text
           * but we want the [example_response] within it to be parsed and to support markdown
           * So go through the prompt block's text, parse [example_response] and leave the rest as plain text
           */
          const parts = contentEncoded.split(/\[example_response\](.*?)\[\/example_response\]/s);
          if (parts.length >= 2) {
            // possibly content before [example_response]
            if (parts[0]) {
              const textToken = state.push('text', '', 0);
              textToken.content = parts[0];
            }

            // Parse the example_response content with markdown
            if (parts[1]) {
              const exampleToken = state.push("wrap_open", "div", 1);
              exampleToken.attrs = [['class', 'snippet-preview-prompt']];
              md.block.parse(parts[1], state.md, state.env, state.tokens);
              state.push("wrap_close", "div", -1);
            }

            // content after [/example_response]
            if (parts[2]) {
              const textToken = state.push('text', '', 0);
              textToken.content = parts[2];
            }
          } else {
            // no match, everything is raw text
            const textToken = state.push('text', '', 0);
            textToken.content = contentEncoded;
          }
          state.push("wrap_close", "div", -1);
          return true;
        }
      });
    });
  }
});